import * as React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { Link } from 'gatsby';
import FeatherIcon from 'feather-icons-react';
import { motion } from 'framer-motion';
import { Suspense, useRef } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Html, BakeShadows, ContactShadows, Environment, Lightformer, Float, OrbitControls, Loader, Backdrop } from '@react-three/drei'
import { EffectComposer, Bloom , SSR} from '@react-three/postprocessing'
import { LayerMaterial, Depth } from 'lamina'
import Stratos from '../components/stratos'


function Model() {
  const ref = useRef()
  return (
    <Suspense fallback={null}>   
        <group position-y={0} dispose={null}>
          <Stratos scale={0.015} position={[0, -1, 0]} rotation={[0, Math.PI * 4, 0]}/>
        </group>         
  </Suspense>
  )
}

const StratosPage = () => (
  
  <Layout>
    

    <Helmet>
      <body className="body-homera"></body>
    </Helmet>

<section className="h-screen flex items-end content-end">

      <Link to={`/work#play/`}>
      <FeatherIcon icon="corner-up-left" size="36" stroke="#fff" className="fixed top-5 left-5 z-50"/>   
      </Link>

      <motion.div 
        initial={{x: 20, opacity: 0}}      
        animate={{x: 0, opacity: 1 }}
        transition={{duration: 0.5, type: "spring", ease: 'easeOut', bounce: 0.4  }}       
        className="absolute p-5 text-owlwhite z-50">  
        <h1 className="display-1 mb-2">Lancia Stratos HF</h1>
        <motion.hr 
          className="mb-2"
          initial={{width: 0}}
          animate={{width: "100%"}}
          transition={{duration: 1.5}}                  
        /> 
        <p className="display-3">C4D // R3F // Click or drag to rotate</p>
      </motion.div>  

      <Canvas 
        dpr={[1, 2]}
        camera={{ position: [0, 0, 15], fov: 25 }}>  
        <color attach="background" args={['#349090']} />
          <Model />   
        <spotLight position={[0, 15, 0]} angle={0.3} penumbra={1} castShadow intensity={0.5} shadow-bias={-0.0001} />
        <ambientLight intensity={0.2} />
        <ContactShadows resolution={512} position={[0, -1, 0]} scale={10} blur={0.5} opacity={0.5} far={10} />

        <Environment frames={Infinity} resolution={256}>
          <MovingSpots />
          {/* Ceiling */}
          <Lightformer intensity={0.75} rotation-x={Math.PI / 2} position={[0, 5, -9]} scale={[10, 10, 1]} />
          {/* Sides */}
          <Lightformer intensity={4} rotation-y={Math.PI / 2} position={[-5, 1, -1]} scale={[20, 0.1, 1]} />
          <Lightformer rotation-y={Math.PI / 2} position={[-5, -1, -1]} scale={[20, 0.5, 1]} />
          <Lightformer rotation-y={-Math.PI / 2} position={[10, 1, 0]} scale={[20, 1, 1]} />
          {/* Accent (red) */}
          <Float speed={5} floatIntensity={2} rotationIntensity={2}>
            <Lightformer form="ring" color="red" intensity={1} scale={10} position={[-15, 4, -18]} target={[0, 0, 0]} />
          </Float>
          {/* Background */}

        </Environment> 
        <OrbitControls
        autoRotate
        autoRotateSpeed={0.6}
        enablePan={false}
        enableZoom={false}
        minPolarAngle={Math.PI / 2.2}
        maxPolarAngle={Math.PI / 2.2}
      />             
      <EffectComposer disableNormalPass>
        <SSR     
        intensity={ 3 }
        power={ 0.7 }
        roughnessFadeOut={ 1 }
        thickness={ 10 }
        ior={ 0 } 
          />
      </EffectComposer>        
      </Canvas>
      <Loader />

  </section>
 
  </Layout>
)

function MovingSpots({ positions = [2, 0, 2, 0, 2, 0, 2, 0] }) {
  const group = useRef()
  useFrame((state, delta) => (group.current.position.z += delta * 15) > 60 && (group.current.position.z = -60))
  return (
    <group rotation={[0, 0.5, 0]}>
      <group ref={group}>
        {positions.map((x, i) => (
          <Lightformer form="circle" intensity={4} rotation={[Math.PI / 2, 0, 0]} position={[x, 4, i * 4]} scale={[3, 1, 1]} />
        ))}
      </group>
    </group>
  )
}

{/* function CameraRig({ v = new THREE.Vector3() }) {
  return useFrame((state) => {
    const t = state.clock.elapsedTime
    state.camera.position.lerp(v.set(Math.sin(t / 5), 0, 10 + Math.cos(t / 5)), 0.05)
    state.camera.lookAt(0, 0, 0)
  })
} */}





export default StratosPage
