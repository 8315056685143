/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { motion } from "framer-motion-3d"

export default function Model({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/lancia_stratos_HF.glb");
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0, -1.93, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.main.geometry}
          material={nodes.main.material}
          position={[63.55, 84.97, -13.11]}
          material-color="#fff"
          material-roughness="0.3" 
          material-metalness="0.8" 
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.glass.geometry}
          material={nodes.glass.material}
          position={[63.55, 84.97, -13.11]}
          material-color="#3b3b3b"  
          material-roughness="0.2"
          material-clearcoat="0.1"  
          material-metalness="0.3"            
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.front_thin_light.geometry}
          material={nodes.front_thin_light.material}
          position={[67.68, 51.17, 231.54]}
          material-color="#fff"
          material-roughness="0.5" 
          material-metalness="0.8" 
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.front_light.geometry}
          material={nodes.front_light.material}
          position={[85.9, 52.88, 229.74]}
          material-color="#fff"
          material-roughness="0.5" 
          material-metalness="0.8" 
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.locks.geometry}
          material={nodes.locks.material}
          position={[112.06, 68.22, -23.47]}
          material-color="#222"
          material-roughness="0.4" 
          material-metalness="0"          
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.indicator.geometry}
          material={nodes.indicator.material}
          position={[105.3, 68.18, 176.3]}
          rotation={[-2.97, 0.1, -2.05]}
          material-color="#EAD8B0"
          material-roughness="0.5" 
          material-metalness="0.8" 
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.brake_discs.geometry}
          material={nodes.brake_discs.material}
          position={[100.41, 41.39, -23.29]}
          material-color="#E63946"
          material-roughness="0.2" 
          material-metalness="0.8"           
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.tyres.geometry}
          material={nodes.tyres.material}
          position={[102.28, 41.45, -23.36]}
          material-color="#222"
          material-roughness="0.4" 
          material-metalness="0"
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.rims.geometry}
          material={nodes.rims.material}
          position={[109.9, 41.46, -23.37]}
          material-color="#cdcdcd"
          material-roughness="0" 
          material-metalness="1"          
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.bolts.geometry}
          material={nodes.bolts.material}
          position={[103.43, 40.77, -23.32]}
          material-color="#333"
          material-roughness="0" 
          material-metalness="1"           
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.handle.geometry}
          material={nodes.handle.material}
          position={[103.04, 95.3, -58.07]}
          rotation={[0, 0, 0.61]}
          material-color="#cdcdcd"
          material-roughness="0" 
          material-metalness="1"            
        />
        <motion.mesh
          initial={{rotateX: 0, rotateY: 0.08}}        
          animate={{rotateX: -1.05}}
          whileTap={{rotateX: 0, rotateY: 0.08}}
          transition={{duration: 1}} 

          castShadow
          receiveShadow
          geometry={nodes.headlight.geometry}
          material={nodes.headlight.material}
          position={[75.63, 83.41, 195.29]}
          rotation={[-1.05, 0.08, -0.05]}
          material-color="#fff"
          material-roughness="0.5" 
          material-metalness="0.8"           
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.headlight_ca.geometry}
            material={nodes.headlight_ca.material}
            material-color="#222"
            material-roughness="0.5" 
            material-metalness="0.8"             
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.light.geometry}
            material={nodes.light.material}
            position={[-1.13, -16.94, 13.04]}
            rotation={[2.64, 0.09, -0.07]}
            material-color="#fff"
            material-roughness="0.5" 
            material-metalness="0.8"               
          />
        </motion.mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.rear_light.geometry}
          material={nodes.rear_light.material}
          position={[85.07, 84.87, -259.81]}
          rotation={[Math.PI / 2, 0, 0]}
          material-color="#E63946"
          material-roughness="0.5" 
          material-metalness="0.8"           
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.rear_light_rim.geometry}
            material={nodes.rear_light_rim.material}
            position={[0, 0.66, 0]}
            rotation={[0, 0, -Math.PI]}
            material-color="#222"
            material-roughness="0.5" 
            material-metalness="0.8"             
          />
        </mesh>
      </group>
    </group>
  );
}

useGLTF.preload("/lancia_stratos_HF.glb");